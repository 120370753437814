import { useState, useEffect } from 'react';
import './index.css';

function HSep(props) {

  var {children, style} = props

  return(
    <div className='hsep'/>
  )
}

export default HSep;
